import React, { ReactElement } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

import RehypeReact from "rehype-react";
import unified from "unified";
import { Node } from "unist";

import { GatsbyImage as Img, IGatsbyImageData } from "gatsby-plugin-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faAward, faDraftingCompass } from "@fortawesome/free-solid-svg-icons";

import { parseLabelsFromNodesLocalized as parseLabels, getLocalizedValue } from "../components/locale-context";
import { ThemeFactory } from "@chweb/commonui";

import { PageInfo, pageInfoByLocale } from "../queries/page-info-helpers";
import { getWineLocalTitle } from "../queries/page-wine-helpers";
import { localizeUrl, reduceToLocale } from "../libs/locale";
import { PageSEO } from "../libs/seo";
import { LocationInfo } from "../libs/location";
import { WineTitleInfo } from "../libs/wine-title";
import { WineListData, WineListDataLocales } from "../queries/fragment-wine-list";
import { AllLocalesLabels } from "../queries/fragment-all-locales-labels";
import { PageInfoData } from "../queries/fragment-page-info";
import getAwards from "../queries/get-awards";
import InlineImage, { InlineImageProps, MediaStore } from "../components/inline-image";

interface MediaContent {
  mediaId: string,
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  },
  title: string
}

export const renderAst = (ast: Node, mediaContent: MediaContent[]): JSX.Element => {

  const mediaStore: MediaStore = {};
  if (mediaContent)
  {
    mediaContent.reduce((acc: MediaStore, media: MediaContent) => {
      acc[media.mediaId] = {
        imageData: media.image.childImageSharp.gatsbyImageData,
        title: media.title
      }
      return acc;
    }, mediaStore);
  }
  // @ts-expect-error some weird RehypeReact typing issue
  const astProcessor = unified().use(RehypeReact, {
    createElement: React.createElement,
    components: {
      inlineimage: (props: InlineImageProps) => <InlineImage {...props} mediaStore={mediaStore} />
    }
  });
  return (astProcessor.stringify(ast) as unknown) as JSX.Element
}

const slugMatch = (locales: WineListDataLocales[], slug: string) => locales.find(l =>
  l.file.childMarkdownRemark.fields.slug === slug);

interface AboutWineProps {
  data: WineTemplateData,
  locale: string,
  slug: string,
  wine: FlattenedWineData 
}

function isAwardsAvailable(wineId: string): boolean {
  // checking if there is at least one award
  const awards = getAwards(wineId, 1);
  return Array.isArray(awards) && awards.length > 0;
}

function AboutWine ({ data, locale, slug, wine }: AboutWineProps): ReactElement {
  const otherWines = data.wines.frontmatter.wines.filter(w => !slugMatch(w.locales, slug));
  const localOtherWines = otherWines.map(o => reduceToLocale(o as any, locale) as unknown as FlattenedWineData);

  const theme = ThemeFactory.make(wine.id);

  const baseClass = theme.header();
  const infoMenuClass = ['w3-padding-16', baseClass];
  const infoMenuItemClass = ['cp-wine-sub', 'w3-center', 'w3-button', 'w3-hover-text-white', theme.link()];

  const otherWineWidgets = localOtherWines.map(o => {
    const wineTitle = getWineLocalTitle(o.frontmatter.pageId.pageTitle, locale)
    const titleInfo = new WineTitleInfo(wineTitle);
    return <Link
      className="w3-mobile w3-button w3-transparent w3-hover-white"
      to={localizeUrl(`/wines${o.fields.slug}`)}
      key={o.fields.slug}
    >
      <span className={'cptitlesmall ' + ThemeFactory.make(o.id).header()}>{ titleInfo.shortLabel }</span>&nbsp;
      {titleInfo.isPremierCru() ?
        <span className={'cptitlesmallsuperscript ' + ThemeFactory.make(o.id).header()}>{titleInfo.premierCruLabel}</span> : null}
    </Link>;
  });

  const labels = parseLabels(data.labels.nodes, locale);
  const pages: {[key: string]: PageInfo} = {};
  data.pages.nodes.reduce((acc, s) => {
    acc[s.yamlId] = getLocalizedValue(pageInfoByLocale(s), locale);
    return acc;
  }, pages);

  const wineTitle = getWineLocalTitle(wine.frontmatter.pageId.pageTitle, locale);
  const titleInfo = new WineTitleInfo(wineTitle);

  const title = titleInfo.shortLabel;
  const subtitle = titleInfo.premierCruLabel;

  const TEMP_TECH_SHEETS_DISABLE = true;
  return (
    <section className = "w3-container w3-white w3-padding-64">
      <header className = "w3-padding-16">
        <div className = "w3-margin-top w3-center w3-hide-large w3-hide-medium">
          <span className= { 'cptitle ' + theme.header() }> { title } </span>
          {subtitle ? <span><br/><span className={'cptitlesuperscript ' + theme.header()}> {subtitle} </span></span> : null}
        </div>
        <div className ="w3-margin-top w3-hide-small">
          <span className= { 'cptitle ' + theme.header() }> { title } </span>
          {subtitle ? <span className={'cptitlesuperscript ' + theme.header()}> {subtitle} </span> : null}
        </div>
      </header>

      <div>
        <div className ="w3-right w3-padding-large w3-mobile">
          <Img
            className="w3-content"
            image={wine.bottleimage.childImageSharp.gatsbyImageData}
            title={titleInfo.longLabel}
            alt={titleInfo.longLabel}
          />
        </div>
        <div>{renderAst(data.page.htmlAst, data.page.frontmatter.mediaContent)}</div>
      </div>

      <div className = { infoMenuClass.join(' ') }>
        <Link
          to = { localizeUrl(`/recipes/?wine=${wine.id}`, locale) }
          className = { infoMenuItemClass.join(' ') }
          title = { pages.recipes.title }
        >
          <div className ="w3-xlarge">
            <FontAwesomeIcon icon = { faUtensils } />
          </div>
          <span>{ pages.recipes.title }</span>
        </Link>
        {
          !isAwardsAvailable(wine.id)
          ? null
          : <Link
            to={localizeUrl(`/awards/?wine=${wine.id}`, locale)}
            className={infoMenuItemClass.join(' ')}
            title={pages.awards.title}
          >
            <div className="w3-xlarge">
              <FontAwesomeIcon icon={faAward} />
            </div>
            <span>{pages.awards.title}</span>
          </Link>
        }
        {
          TEMP_TECH_SHEETS_DISABLE
            ? null
            : <Link
              to = { localizeUrl('/technical-sheets/', locale) }
              className = { infoMenuItemClass.join(' ') }
              title = { pages.technical.title }
            >
              <div className ="w3-xlarge">
                <FontAwesomeIcon icon = { faDraftingCompass } />
              </div>
              <span>{ pages.technical.title }</span>
            </Link>
        }
      </div>

      <div className ="w3-margin-top" style = {{ clear: 'both' }}>
        <span>{ labels.wineSectionSeeAlso }</span>
        <span className = "w3-margin">
          { otherWineWidgets }
        </span>
      </div>

    </section>
  );
}

interface WineTemplateProps {
  data: WineTemplateData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function WineTemplate ({ data, pageContext, location }: WineTemplateProps): ReactElement {
  const { locale, slug } = pageContext;

  const currentWine = data.wines.frontmatter.wines.find(w => slugMatch(w.locales, slug));
  const localWine = reduceToLocale(currentWine as any, locale) as unknown as FlattenedWineData;

  const seo = PageSEO.fromLocation(location);
  seo.description = data.page.excerpt;
  seo.image = data.page.frontmatter.pageId.seoImage;

  const wineLocalTitle = getWineLocalTitle(localWine.frontmatter.pageId.pageTitle, locale);
  return (
    <Layout locale={locale} location={location} title = { wineLocalTitle } seo = {seo}>
      <AboutWine
        locale = { locale }
        slug = { slug }
        data = { data }
        wine = { localWine } />
    </Layout>
  );
}

interface WineTemplateData {
  wines: {
    frontmatter: {
      wines: WineListData[]
    }
  },
  page: {
    frontmatter: {
      pageId: {
        seoImage: string
      },
      mediaContent: MediaContent[]
    },
    htmlAst: Node,
    excerpt: string
  },
  labels: {
    nodes: AllLocalesLabels[]
  },
  pages: {
    nodes: PageInfoData[] 
  }
}

interface FlattenedWineData {
  id: string,
  bottleimage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  },
  icon: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  },
  frontmatter: {
    pageId: PageInfoData,
    notes: {
      vintage: number,
      note: string
    },
    mediaContent: MediaContent[]
  },
  html: string,
  fields: {
    slug: string
  }
}

export const query = graphql`
query ($includeBottle: Boolean = true, $includeDescriptions: Boolean = false, $slug: String!) {
  wines: markdownRemark(fields: {slug: {eq: "/wines-list/"}}) {
    frontmatter {
      wines {
        ...wineList
      }
    }
  }
  page: markdownRemark(fields: {slug: {eq: $slug}}) {
    frontmatter {
      pageId {
        seoImage
      }
      mediaContent {
			  mediaId
        image {
          childImageSharp {
					  gatsbyImageData
          }
        }
        title
      }
    },
    htmlAst
    excerpt(pruneLength: 250)
  }
  labels: allLabelsTranslatedYaml(filter: {name: {in: [
    "wineSectionSeeAlso",
  ]}}) {
    nodes {
      ...AllLocalesLabels
    }
  }
  pages: allPageIndexYaml(filter: {yamlId: {in: ["recipes", "awards", "technical"]}}) {
    nodes {
      ...PageInfo
    }
  }
}
`;
