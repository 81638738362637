import React, { ReactElement } from 'react';

import { GatsbyImage as Img } from 'gatsby-plugin-image';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { isMarkUpImage, MarkUpImage } from '../components/markup-image';
import { ImagePopupEvent, ContentWithDescription, ImageWithDescription } from './image-popup';

function handleClick (imageInfo: ContentWithDescription): void {
  PubSub.publishSync(ImagePopupEvent, imageInfo);
}

interface ImageProps {
  imageElement: ImageWithDescription,
  showTextLabel?: boolean
}

function Image({ imageElement, showTextLabel, }: ImageProps): React.ReactElement {
  const text = imageElement.getDescription();
  const image = imageElement.getImage();

  if (!image)
    return (<></>);

  const imageObject = isMarkUpImage(image as IGatsbyImageData)
    ? <MarkUpImage image = { image } />
    : <Img image = { image } style = {{ width: '100%' }} title = { text } alt = { text } />;

  const textStyle = 'w3-opacity-off';

  const imageContent = <div
    onClick = { () => handleClick(new ContentWithDescription(text, imageObject)) }
  >
    { imageObject }
    {
      showTextLabel
        ? <div className="w3-display-bottomleft w3-container w3-white w3-opacity-min"
          style={{ width: '100%', opacity: '0.9' }}>
          <p className={textStyle} style={{ paddingTop: '5px', paddingBottom: '5px', margin: '0' }}>
            {text}
          </p>
        </div>
        : null
    }
  </div>;

  return (
      <div className = {"w3-display-container "} >
        { imageContent }
      </div>
  );
}

export type MediaStore = {
  [mediaId: string]: {
    imageData: IGatsbyImageData
    title: string
  }
}

export type InlineImageProps = {
  width?: string,
  height?: string,
  className?: string,
  showTextLabel?: boolean,
  mediaid?: string,
  mediaStore: MediaStore
};

export default function InlineImage(props: InlineImageProps): ReactElement {
  const { showTextLabel, width, height, className, mediaid, mediaStore } = props;

  const wrapperStyle: { cursor: string, overflow: string, height?: string, width?: string } = {
    cursor: 'pointer', overflow: 'hidden', height: undefined, width: undefined
  };
  if (height)
    wrapperStyle.height = height;
  if (width)
    wrapperStyle.width = width;

  if (!mediaid)
    return <></>;

  const media = mediaStore[mediaid];
  if (!media)
    return <></>;

  return (
    <div style={wrapperStyle} className={"w3-mobile " + className}>
      <Image imageElement={new ImageWithDescription(media.title || '', media.imageData, media.title|| '')}
        showTextLabel={showTextLabel} />
    </div>
  );
}
